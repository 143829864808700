import React from 'react';
import { Col } from 'react-bootstrap';
import OilerTypography from '@oilerKit/OilerTypography';
import styled from 'styled-components';
import OilerButton from '@oilerKit/OilerButton';
import { down } from 'styled-breakpoints';

const StyledCol = styled(Col)`
    display: flex;
    flex-direction: column;
    ${down('md')} {
        margin-bottom: 80px;
    }
`;

const CTAButton = styled.div`
    align-self: flex-start;
    margin-top: 30px;
`;

const ActionText = () => {
    return (
        <StyledCol lg={6}>
            <OilerTypography
                variant={'h1'}
                weight={'semiBold'}
                mb={20}
                align={'left'}
            >
                Hedge yourself against network shocks
            </OilerTypography>

            <OilerTypography align={'left'}>
                Trade blockchain parameters on Oiler
            </OilerTypography>

            <CTAButton>
                <OilerButton href="https://docs.oiler.network/oiler-network/">
                    LEARN MORE
                </OilerButton>
            </CTAButton>
        </StyledCol>
    );
};

export default ActionText;
