import OilerButton from '@oilerKit/OilerButton';
import OilerContainer from '@oilerKit/OilerContainer';
import OilerTypography from '@oilerKit/OilerTypography';
import { Col, Row } from 'react-bootstrap';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 100%;
    //height: 100vw;
    display: flex;
    flex-direction: column;
    background:#222222;
    text-align: center;
    align-items: center;
`;
const StyledCol = styled(Col)`
    display: flex;
    flex-direction: column;
`;

const ButtonContainer = styled.div`
    align-self: flex-end;
    margin-top: 30px;
`;

const StyledOilerTypography = styled(OilerTypography)`
    margin-top: 30px;
    color: #ffffff;
`
const StatusContainer = styled.div`
    align-self: flex-start;
    margin-top: 10px;
    border-radius: 50px;
    background-color:${(props) => props.theme.primary["500"]};
    padding-left:10px;
    padding-right:10px;
    padding-top:2px;
    padding-bottom:2px;
`;


const NaftaContent = () => {
    return (
        <Wrapper id='Forwards'>
            <StyledCol lg={6}>
                    <StyledOilerTypography variant='h1' weight='semiBold' color='primary' align='left'> PITCH LAKE </StyledOilerTypography>
                    <StatusContainer>
                        <OilerTypography variant='caption' weight='semiBold' color='black'>WORK IN PROGRESS</OilerTypography>
                    </StatusContainer>
                    <StyledOilerTypography weight='light' align='left'>
                    DeFi vaults which allow you to trade Ethereum mainnet basefee.  Pitch Lake uses StarkNet STARKSs to calculate basefee TWAP in a given month to be used for cash settlement. Access to verified Ethereum mainnet block header is achieved with the help of Oiler Fossil.
                    </StyledOilerTypography>
                    <ButtonContainer>
                        <OilerButton href='https://pitchlake.vercel.app/'>TRY IT OUT</OilerButton>
                    </ButtonContainer>
                    
            </StyledCol>
        </Wrapper>
       
    )
}

export default NaftaContent
