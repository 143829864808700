import OilerButton from '@oilerKit/OilerButton';
import OilerTypography from '@oilerKit/OilerTypography';
import { Col, Row } from 'react-bootstrap';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 100%;
    //height: 100vw;
    display: flex;
    flex-direction: column;
    background:#222222;
    text-align: center;
    align-items: center;
    padding-bottom:50px;

`;
const StyledCol = styled(Col)`
    display: flex;
    flex-direction: column;
`;

const ButtonContainer = styled.div`
    align-self: flex-end;
    margin-top: 30px;
`;

const StyledOilerTypography = styled(OilerTypography)`
    margin-top: 30px;
    color: #ffffff;
`
const StatusContainer = styled.div`
    align-self: flex-start;
    margin-top: 10px;
    border-radius: 50px;
    background-color:${(props) => props.theme.primary["500"]};
    padding-left:10px;
    padding-right:10px;
    padding-top:2px;
    padding-bottom:2px;
`;


const NaftaContent = () => {
    return (
        <Wrapper id='Options'>
            <StyledCol lg={6}>
                    <StyledOilerTypography variant='h1' weight='semiBold' color='primary' align='left'> STAKING</StyledOilerTypography>
                    <StatusContainer>
                        <OilerTypography variant='caption' weight='semiBold' color='black'>ENDED</OilerTypography>
                    </StatusContainer>
                    <StyledOilerTypography weight='light' align='left'>
                        
                    </StyledOilerTypography>
                    <ButtonContainer>
                        <OilerButton href='https://staking.oiler.network/'>TRY IT OUT</OilerButton>
                    </ButtonContainer>
                    
            </StyledCol>
        </Wrapper>
       
    )
}

export default NaftaContent
