import styled from "styled-components";
import { Navbar } from "react-bootstrap";
import OilerLogo from "@oilerKit/themes/img/OilerLogo/logo.svg";
import OilerIcon from "@oilerKit/OilerIcon";
import React from "react";

const StyledBootstrapNavBrand = styled(Navbar.Brand)`
  position: relative;
  margin-top: 38px;
  margin-bottom: 24px;
  align-self: flex-start;
  margin-left: 56px;
  color: ${(props) => props.theme.black["500"]};

  > img {
    width: 180px;
  }
`;

const Headings = styled.div`
  position: relative;
  margin-top: 10px;
  > div:first-of-type {
    margin: auto;
    background-color: ${(props) => props.theme.white};
    width: fit-content;
    padding: 0 20px;

    p {
      font-size: 10px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.48;
      margin-bottom: 0;
    }
  }

  > div:last-of-type {
    position: absolute;
    left: -56px;
    margin: auto;
    background-color: ${(props) => props.theme.primary["500"]};
    width: 250px;
    padding: 0 20px;

    p {
      font-size: 10px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.48;
      margin-bottom: 0;
    }
  }
`;

const Logo = () => {
  return (
    <StyledBootstrapNavBrand href="/">
      <OilerIcon icon={OilerLogo} alt="Oiler logo" />

      <Headings>
        <div>
          <p>BLOCKCHAIN NATIVE DERIVATIVES</p>
        </div>
        <div>
          <p>HEDGING GAS AND NETWORK SHOCKS</p>
        </div>
      </Headings>
    </StyledBootstrapNavBrand>
  );
};

export default Logo;
