import React, { FC, SyntheticEvent } from "react";
import { Button } from "react-bootstrap";
import styled, { css } from "styled-components";
import { LayoutProps, space, SpaceProps, variant } from "styled-system";
import { ButtonProps } from "react-bootstrap/Button";

const baseProperties = css`
  border: 2px solid ${(props) => props.theme.primary["500"]};
  color: ${(props) => props.theme.black["500"]};
  background: ${(props) => props.theme.primary["500"]};
  :hover {
    border: 2px solid ${(props) => props.theme.primary["500"]};
    background: ${(props) => props.theme.primary["600"]};
    color: #010101;
  }
  :disabled {
    border-color: ${(props) => props.theme.primary["800"]};
    background: ${(props) => props.theme.primary["800"]};
    border-width: 2px;
    color: ${(props) => props.theme.primary["700"]};
    opacity: 1;
  }
  &&&:focus,
  &&&:active {
    border-color: ${(props) => props.theme.primary["600"]};
    background: ${(props) => props.theme.primary["600"]};
    border-width: 2px;
    color: ${(props) => props.theme.primary["900"]};
    opacity: 1;
  }
`;

const secondaryProperties = css`
  background: ${(props) => props.theme.secondary["500"]};
  border-color: ${(props) => props.theme.secondary["500"]};
  color: #ffffff;
  :hover {
    border-color: ${(props) => props.theme.secondary["500"]};
    background: ${(props) => props.theme.secondary["600"]};
    border-width: 2px;
    color: #ffffff;
  }
  :disabled {
    border-color: ${(props) => props.theme.secondary["700"]};
    background: ${(props) => props.theme.secondary["700"]};
    border-width: 2px;
    color: ${(props) => props.theme.secondary["400"]};
    opacity: 1;
  }
  &&&:focus,
  &&&:active {
    border-color: ${(props) => props.theme.secondary["600"]};
    background: ${(props) => props.theme.secondary["600"]};
    border-width: 2px;
    color: ${(props) => props.theme.secondary["900"]};
    opacity: 1;
  }
`;
const dangerProperties = css`
  background: ${(props) => props.theme.danger["500"]};
  border-color: ${(props) => props.theme.danger["500"]};
  color: #ffffff;
  :hover {
    border-color: ${(props) => props.theme.danger["500"]};
    background: ${(props) => props.theme.danger["600"]};
    border-width: 2px;
    color: #ffffff;
  }
  :disabled {
    border-color: ${(props) => props.theme.danger["700"]};
    background: ${(props) => props.theme.danger["700"]};
    border-width: 2px;
    color: ${(props) => props.theme.danger["400"]};
    opacity: 1;
  }
  &&&:focus,
  &&&:active {
    border-color: ${(props) => props.theme.danger["600"]};
    background: ${(props) => props.theme.danger["600"]};
    border-width: 2px;
    color: ${(props) => props.theme.danger["900"]};
    opacity: 1;
  }
`;
const darkProperties = css`
  background: ${(props) => props.theme.black["500"]};
  border-color: ${(props) => props.theme.black["500"]};
  color: ${(props) => props.theme.primary["500"]};
  :hover {
    border-color: ${(props) => props.theme.primary["800"]};
    background: ${(props) => props.theme.primary["800"]};
    border-width: 2px;
    color: ${(props) => props.theme.primary["100"]};
  }
  ,
  :disabled {
    border-color: ${(props) => props.theme.primary["700"]};
    background: ${(props) => props.theme.primary["700"]};
    border-width: 2px;
    color: ${(props) => props.theme.primary["400"]};
    opacity: 1;
  }
`;

const outlined = (type = "primary") =>
  variant({
    prop: "type",
    variants: {
      outlined: {
        background: "transparent",
        color: (theme: any) => theme[type]["500"],
      },
    },
  });

// TODO fix way of coloring font for 'primary' button in different types
const StyledBootstrapButton = styled(Button)`
  border-radius: 0;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
  ${baseProperties};
  ${(props) => (props.variant === "secondary" ? secondaryProperties : "")};
  ${(props) => (props.variant === "danger" ? dangerProperties : "")};
  ${(props) => (props.variant === "dark" ? darkProperties : "")};
  ${(props) => outlined(props.variant)};
  ${space}
`;

interface OilerButtonProps
  extends ButtonProps,
    SpaceProps,
    Omit<LayoutProps, "size"> {
  type?: "outlined";
  variant?: "danger" | "secondary" | "dark";
  fullWidth?: boolean;
  href?: string;
}

const OilerButton: FC<OilerButtonProps> = (props: OilerButtonProps) => {
  const open = (e: SyntheticEvent, href: string) => {
    e.preventDefault();
    window.open(href, "_blank");
  };
  return (
    <StyledBootstrapButton
      onClick={(e: SyntheticEvent) => (props.href ? open(e, props.href) : null)}
      {...props}
    >
      {props.children}
    </StyledBootstrapButton>
  );
};

export default OilerButton;
