import styled from 'styled-components';
import { scroller } from "react-scroll";
import { Col, Row } from 'react-bootstrap';
import React, { SyntheticEvent, useState } from 'react';
import OilerIcon from '@oilerKit/OilerIcon';
import STAKING2 from '@assets/staking2.svg';
import OPTIONS from '@assets/options.svg';
import FORWARDS from '@assets/forwards.svg';
import NAFTA from '@assets/nafta.svg';
import APP from '@assets/app.svg';
import { down } from 'styled-breakpoints';
import OilerModal from '@oilerKit/OilerModal';
import OilerTypography from '@oilerKit/OilerTypography';

const StyledCol = styled(Col)`
    margin: 0;
    margin-bottom:30px;
    border-color: ${(props) => props.theme.black[500]};
    ${down('sm')} {
        margin-bottom: 40px;
    }
`;

const StyledIcon = styled(OilerIcon)`
    cursor: pointer;
    border: 2px solid ${(props) => props.theme.primary['500']};
    width: 100%;
    &:hover {
        border-color: ${(props) => props.theme.secondary['500']};
    }
    ${down('sm')} {
        width: auto;
    }
`;

const Apps = () => {
    const [showWENModal, setShowWENModal] = useState(false);
    const open = (e: SyntheticEvent) => {
        e.preventDefault();
        window.open('https://staking.oiler.network', '_blank');
    };
    const openDiscord = (e: SyntheticEvent) => {
        e.preventDefault();
        window.open('https://discord.com/invite/zV9fz9967a', '_blank');
    };
    const scrollToSection = (id: string) => {
        scroller.scrollTo(id, {
          duration: 800,
          delay: 0,
          smooth: "easeInOutQuart",
        });
    };
    return (
        <Col lg={6}>
            <Row>
                <StyledCol sm={4}>
                    <StyledIcon 
                    onClick={()=> scrollToSection('Nafta')} 
                    icon={NAFTA} 
                    alt="NAFTA" />
                </StyledCol>
                <Col sm={4}>
                    <StyledIcon
                        onClick={()=> scrollToSection('Staking')} 
                        icon={STAKING2}
                        alt="STAKING"
                    />
                </Col>
            </Row>
            <Row>
                <StyledCol sm={4}>
                    <StyledIcon 
                        onClick={()=> scrollToSection('Options')} 
                        icon={OPTIONS} 
                        alt="OPTIONS" />
                </StyledCol>
                <Col sm={4}>
                    <StyledIcon
                        onClick={()=> scrollToSection('Forwards')} 
                        icon={FORWARDS}
                        alt="FORWARDS"
                    />
                </Col>
            </Row>
            <OilerModal
                show={showWENModal}
                title="ALPHA PROGRAM"
                centered
                onHide={() => setShowWENModal(false)}
                confirmButtonText="JOIN DISCORD"
                confirmButtonOnClick={openDiscord}
                closeButton
            >
                <OilerTypography>
                    We have just launched our alpha. <br />
                    <br />
                    To join our alpha testing program, please join our Discord
                    server.
                </OilerTypography>
            </OilerModal>
        </Col>
    );
};

export default Apps;
