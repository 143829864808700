import React from 'react';
import styled from 'styled-components';
import { down, up } from 'styled-breakpoints';
import MediaList from '@oilerKit/OilerNav/MediaList';
import { gap } from '@oilerKit/OilerPolyfill';

const Footer = styled.div`
    background:#282828;
    display: flex;
    flex-direction: row;
    width: 80%;
    margin-top: 80px;
    //gap: 5px;
    align-items: center;
    justify-content: center;
    ${gap(30, 'b')}
`;

const FooterText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 10px;
    min-width: 50%;
    color: ${(props) => props.theme.white};
    font-weight: ${(props) => props.theme.fontWeight.light};
    //gap: 10px;
    ${gap(20, 'r')}

    a,
    a:hover {
        font-size: inherit;
        font-weight: inherit;
        padding: 0;
        color: ${(props) => props.theme.primary[500]};
        text-decoration: none;
        ${down('sm')} {
            color: ${(props) => props.theme.black[500]};
        }
    }
    ${down('xs')} {
        flex-direction: column;
    }
    ${down('sm')} {
        flex-direction: column;
        min-width: 100%;
        color: ${(props) => props.theme.black[500]};
        background: ${(props) => props.theme.white};
        ${gap(10, 'b')}
    }
`;

const Copyright = styled.a`
display: flex;
justify-content: center;
    align-items: center;
    padding: 15px 10px;
    min-width: 50%;
    && {
        ${up('md')} {
            color: ${(props) => props.theme.primary[500]};
        }
    }
`;

interface OilerFooterProps {
    links?: any[];
}

const OilerFooter = (props: OilerFooterProps) => {
    const { links } = props;

    const currentYear = new Date().getFullYear();
    return (
        <Footer>
            <MediaList className="d-md-none align-self-center" />
            <Copyright href="/">
                    Copyright &#169; {currentYear} Oiler. All rights reserved. 
            </Copyright>
    
            <FooterText>
                {links
                    ? links?.map((link: any) => (
                          <a
                              href={link.url}
                              key={link.url}
                              target="blank"
                              rel="noopener noreferrer"
                          >
                              {link.name}
                          </a>
                      ))
                    : null}
             </FooterText>
            
        </Footer>
    );
};

export default OilerFooter;
