import React, { FC, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

interface OilerIconProps extends SpaceProps {
    icon: string;
    icon2x?: string;
    icon3x?: string;
    height?: number;
    width?: string;
    alt?: string;
    onClick?: (e: SyntheticEvent) => void;
}

const StyledImg = styled.img`
    ${space}
`;

const OilerIcon: FC<OilerIconProps> = (props: OilerIconProps) => {
    const { icon, icon2x, height, alt, onClick } = props;
    const iconX2 = icon2x ? ` ${icon2x} 2x  ` : '';

    return (
        <StyledImg
            onClick={onClick}
            {...props}
            style={{ height: height + 'px', objectFit: 'contain' }}
            srcSet={icon + ` 1x, ${iconX2}`}
            alt={alt}
        />
    );
};

export default OilerIcon;
