import React from 'react';
import vid from '@assets/bg.mp4';
import img from '@assets/bg-still.jpg';
import styled from 'styled-components';

const Wrapper = styled.div`
    video {
        object-fit: cover;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
    }
`;

const Video = () => {
    return (
        <Wrapper>
            <video playsInline autoPlay muted loop poster={img}>
                <source src={vid} type="video/mp4" />
                {/*<source src={vid} type="video/webm" />*/}
            </video>
        </Wrapper>
    );
};

export default Video;
