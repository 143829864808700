import React from 'react';
import Logo from './Logo';
import MediaList from './MediaList';
import styled from 'styled-components';
import { Navbar } from 'react-bootstrap';
// import ConnectedInfo from './ConnectedInfo';
import { space } from 'styled-system';
import { between, up } from 'styled-breakpoints';
// import OilerButton from "@oilerKit/OilerButton";

const StyledBootstrapNavbar = styled(Navbar)<Navbar>`
    min-height: 282px;
    background-color: transparent;
    position: relative;
    align-items: start !important;
    flex-direction: column;
    ${up('sm')} {
        flex-direction: row;
    }
    ${space}
`;

// const StyledCollapse = styled(Navbar.Collapse)`
//     margin-top: 83px;
//     margin-left: 30px;
// `;
//
// const StyledLink = styled(Nav.Link)`
//     &&.nav-link {
//         color: ${(props) => props.theme.primary['500']};
//
//         &:hover,
//         &:focus {
//             color: ${(props) => props.theme.secondary['500']};
//         }
//     }
//     &&:last-child {
//         color: white;
//     }
// `;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    ${up('sm')} {
        margin-left: auto;
    }
    ${between('sm', 'lg')} {
        width: 100%;
    }
`;

const OilerNav = () => {
    return (
        <StyledBootstrapNavbar expand={'lg'} px={3} mb={0}>
            <Logo />
            {/*<StyledCollapse id="responsive-navbar-nav">*/}
            {/*    <Nav className="mr-auto">*/}
            {/*        <StyledLink*/}
            {/*            target="_blank"*/}
            {/*            href="https://docs.oiler.network/oiler-network/"*/}
            {/*        >*/}
            {/*            DOCUMENTATION*/}
            {/*        </StyledLink>*/}
            {/*        <StyledLink*/}
            {/*            target="_blank"*/}
            {/*            href="https://medium.com/oiler-network"*/}
            {/*        >*/}
            {/*            BLOG*/}
            {/*        </StyledLink>*/}
            {/*        <StyledLink target="_blank" href="#">*/}
            {/*            WE'RE HIRING!*/}
            {/*        </StyledLink>*/}
            {/*    </Nav>*/}
            {/*</StyledCollapse>*/}

            <Wrapper>
                <MediaList className="d-none d-md-flex" />
                {/*    <ConnectedInfo />*/}
            </Wrapper>
        </StyledBootstrapNavbar>
    );
};

export default OilerNav;
