import React from 'react';
import styled from 'styled-components';
import { Nav } from 'react-bootstrap';
import {
    DISCORD_URL,
    GITHUB_URL,
    LINKEDIN_URL,
    MEDIUM_URL,
    TELEGRAM_URL,
    TWITTER_URL,
} from '@config/linkConstants';
import twitter from '@oilerKit/assets/icons/social/twitter.svg';
import telegram from '@oilerKit/assets/icons/social/telegram.svg';
import discord from '@oilerKit/assets/icons/social/discord.svg';
import github from '@oilerKit/assets/icons/social/github.svg';
import medium from '@oilerKit/assets/icons/social/medium.svg';
import linkedin from '@oilerKit/assets/icons/social/linkedin.svg';
import OilerIcon from '../OilerIcon';
import { up } from 'styled-breakpoints';
import { gap } from '@oilerKit/OilerPolyfill';
import OilerTypography from '@oilerKit/OilerTypography';

const StyledBootstrapNav = styled(Nav)`
    height: 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-self: flex-end;
    margin: 40px 0;
    width: 100%;
    ${up('md')} {
        margin-left: auto;
        //gap: 30px;
        justify-content: flex-end;
        ${gap(50, 'r')}
    }

    img {
        height: 15px;
    }
`;

const MediaList = (props: any) => {
    return (
        <StyledBootstrapNav {...props}>
            <a href={MEDIUM_URL} target="_blank" rel="noopener noreferrer">
                <OilerTypography>BLOG</OilerTypography>
            </a>
            <a href={TELEGRAM_URL} target="_blank" rel="noopener noreferrer">
                <OilerIcon icon={telegram} alt="Telegram logo" />
            </a>
            <a href={TWITTER_URL} target="_blank" rel="noopener noreferrer">
                <OilerIcon icon={twitter} alt="Twitter logo" />
            </a>
            <a href={DISCORD_URL} target="_blank" rel="noopener noreferrer">
                <OilerIcon icon={discord} alt="Discord logo" />
            </a>
            {/*<a href={GITHUB_URL} target="_blank" rel="noopener noreferrer">
                <OilerIcon icon={github} alt="GitHub logo" />
            </a>*/ }
            { /*<a href={MEDIUM_URL} target="_blank" rel="noopener noreferrer">
                <OilerIcon icon={medium} alt="Medium logo" />
            </a> */}
            {/*<a href={LINKEDIN_URL} target="_blank" rel="noopener noreferrer">
                <OilerIcon icon={linkedin} alt="Linkedin logo" />
            </a> */}
        </StyledBootstrapNav>
    );
};

export default MediaList;
