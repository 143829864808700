import styled from 'styled-components';
import { Row } from 'react-bootstrap';
import React from 'react';
import Apps from '@components/Apps';
import ActionText from '@components/ActionText';
// import OilerButton from '@oilerKit/OilerButton';
import { gap } from '@oilerKit/OilerPolyfill';
// import { up } from 'styled-breakpoints';

const StyledRow = styled.div`
    width:100%
    margin: 0;
    display: flex;
    align-items: center;
    margin-left: 100px;
    gap: 100px;
`;


// const Actions = styled(StyledRow)`
//     margin-bottom: 100px;
//     //gap: 15px;
//     ${gap(15, 'b')}
//     ${up('sm')} {
//         ${gap(15, 'r')}
//     }
// `;

const Content = () => {
    return (
        <>
            {/*<Actions className="justify-content-center d-lg-none d-flex">*/}
            {/*    <Col sm="auto">*/}
            {/*        <OilerButton*/}
            {/*            type={'outlined'}*/}
            {/*            href="https://docs.oiler.network/oiler-network/"*/}
            {/*        >*/}
            {/*            DOCUMENTATION*/}
            {/*        </OilerButton>*/}
            {/*    </Col>*/}
            {/*    <Col sm="auto">*/}
            {/*        <OilerButton*/}
            {/*            type={'outlined'}*/}
            {/*            href="https://medium.com/oiler-network"*/}
            {/*        >*/}
            {/*            BLOG*/}
            {/*        </OilerButton>*/}
            {/*    </Col>*/}
            {/*    <Col sm="auto">*/}
            {/*        <OilerButton type={'outlined'}>WE'RE HIRING!</OilerButton>*/}
            {/*    </Col>*/}
            {/*</Actions>*/}
                <StyledRow>
                    <ActionText />
                    <Apps />
                </StyledRow>
        </>
    );
};

export default Content;
