import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { flexbox, FlexboxProps, space, SpaceProps } from 'styled-system';

interface OilerTypographyProps extends SpaceProps, FlexboxProps {
    variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'caption';
    color?: 'primary' | 'secondary' | 'danger' | 'black';
    italic?: boolean;
    display?: 'block' | 'inline';
    weight?:
        | 'thin'
        | 'light'
        | 'regular'
        | 'semiBold'
        | 'bold'
        | 'extraBold'
        | 'black';
    children: ReactNode;
    align?: 'left' | 'center' | 'right' | 'justify';
    uppercase?: boolean;
}

const Typography = ({ variant, ...otherProps }: any) => {
    const Tag = variant ?? 'span';
    return <Tag {...otherProps} />;
};

const StyledOilerTypography = styled(Typography)`
    color: ${(props) =>
        props.color
            ? props.theme[props.color]['500']
            : props.theme.primary['500']};
    font-weight: ${(props) =>
        props.weight
            ? props.theme.fontWeight[props.weight]
            : props.theme.fontWeight.medium};
    font-style: ${(props) => (props.italic ? 'italic' : 'normal')};
    display: ${(props) => props.display ?? 'initial'};
    text-align: ${(props) => props.align ?? 'unset'};
    font-size: ${(props) => (props.variant === 'caption' ? '0.75rem' : '')};
    text-transform: ${(props) => (props.uppercase ? 'uppercase' : 'none')};
    margin-bottom: 0;
    ${space}
    ${flexbox}
`;

const OilerTypography = (props: OilerTypographyProps) => {
    return (
        <StyledOilerTypography {...props}>
            {props.children}
        </StyledOilerTypography>
    );
};

export default OilerTypography;
