import OilerButton from '@oilerKit/OilerButton';
import OilerContainer from '@oilerKit/OilerContainer';
import OilerTypography from '@oilerKit/OilerTypography';
import { SyntheticEvent } from 'react';
import { Col, Row } from 'react-bootstrap';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 100%;
    //height: 100vw;
    display: flex;
    flex-direction: column;
    background:#222222;
    text-align: center;
    align-items: center;
`;
const StyledCol = styled(Col)`
    display: flex;
    flex-direction: column;
`;

const ButtonContainer = styled.div`
    align-self: flex-end;
    margin-top: 30px;
`;

const StyledOilerTypography = styled(OilerTypography)`
    margin-top: 30px;
    color: #ffffff;
`
const StatusContainer = styled.div`
    align-self: flex-start;
    margin-top: 10px;
    border-radius: 50px;
    background-color:${(props) => props.theme.primary["500"]};
    padding-left:10px;
    padding-right:10px;
    padding-top:2px;
    padding-bottom:2px;
`;

const open = (e: SyntheticEvent) => {
    e.preventDefault();
    window.open('https://staking.oiler.network', '_blank');
};

const NaftaContent = () => {
    return (
        <Wrapper id='Staking'>
            <StyledCol lg={6}>
                    <StyledOilerTypography variant='h1' weight='semiBold' color='primary' align='left'> NAFTA</StyledOilerTypography>
                    <StatusContainer>
                        <OilerTypography variant='caption' weight='semiBold' color='black'>Flash-Owned NFTs</OilerTypography>
                    </StatusContainer>
                    <StyledOilerTypography weight='light' align='left'>
                        Nafta is a protocol that facilitates NFT Flash Loans & Long Term Renting without any collateral. This is achieved by wrapping an original NFT when it is pooled with Nafta ecosystem. Hence rented assets are only used for the utilities they provide. 
                    </StyledOilerTypography>
                    <ButtonContainer>
                        <OilerButton href="https://nafta.market" >TRY IT OUT</OilerButton>
                    </ButtonContainer>
                    
            </StyledCol>
        </Wrapper>
       
    )
}

export default NaftaContent
