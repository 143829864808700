// custom interface created in styled.d.ts based on properties below
import { DefaultTheme } from 'styled-components';

export const darkTheme: DefaultTheme = {
    primary: {
        100: '#fdfaee',
        200: '#faf5dd',
        300: '#f8f1cb',
        400: '#f5ecba',
        500: '#f3e7a9',
        600: '#c2b987',
        700: '#928b65',
        800: '#615c44',
        900: '#312e22',
    },
    secondary: {
        100: '#fde4ce',
        200: '#fac99e',
        300: '#f8af6d',
        400: '#f5943d',
        500: '#f3790c',
        600: '#c2610a',
        700: '#924907',
        800: '#613005',
        900: '#311802',
    },
    danger: {
        100: '#f3d2da',
        200: '#e8a5b6',
        300: '#dc7891',
        400: '#d14b6d',
        500: '#c51e48',
        600: '#9e183a',
        700: '#76122b',
        800: '#4f0c1d',
        900: '#27060e',
    },
    success: {
        100: '#ccf0db',
        200: '#99e2b7',
        300: '#66d393',
        400: '#33c56f',
        500: '#00B64B',
        600: '#00923c',
        700: '#006d2d',
        800: '#00491e',
        900: '#00240f',
    },
    black: {
        500: '#000000',
    },
    white: '#FFFFFF',
    fontWeight: {
        thin: 100,
        light: 300,
        regular: 400,
        medium: 500,
        semiBold: 600,
        bold: 700,
        extraBold: 800,
        black: 900,
    },
};
