import OilerButton from '@oilerKit/OilerButton';
import OilerContainer from '@oilerKit/OilerContainer';
import OilerTypography from '@oilerKit/OilerTypography';
import { Col, Row } from 'react-bootstrap';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 100%;
    //height: 100vw;
    display: flex;
    flex-direction: column;
    background:#222222;
    text-align: center;
    align-items: center;
`;
const StyledCol = styled(Col)`
    display: flex;
    flex-direction: column;
`;

const ButtonContainer = styled.div`
    align-self: flex-end;
    margin-top: 30px;
`;

const StyledOilerTypography = styled(OilerTypography)`
    margin-top: 50px;
    color: #ffffff;
`
const StatusContainer = styled.div`
    align-self: flex-start;
    margin-top: 10px;
    border-radius: 50px;
    background-color:${(props) => props.theme.primary["500"]};
    padding-left:10px;
    padding-right:10px;
    padding-top:2px;
    padding-bottom:2px;
`;


const NaftaContent = () => {
    return (
        <Wrapper id='Nafta'>
            <StyledCol lg={6}>
                    <StyledOilerTypography variant='h1' weight='semiBold' color='primary' align='left'> FOSSIL</StyledOilerTypography>
                    <StatusContainer>
                        <OilerTypography variant='caption' weight='semiBold' color='black'>API</OilerTypography>
                    </StatusContainer>
                    <StyledOilerTypography weight='light' align='left'>
                    Fossil is a set of smart contracts and infrastructure running on both Starknet and Ethereum L1. Its purpose is to enable Starknet smart contracts to trustlessly access any data that ever appeared on ethereum such as: state, transactions, logs, block parameters.<br></br>
                    The purpose of Fossil API is to facilitate interaction with Fossil contracts. This API hides all the complexity from the end user behind a REST API and sends webhooks once the results are ready.

The Fossil API can also batch different calls to Fossils together, optimizing our calls to L1.

                    </StyledOilerTypography>
                    <ButtonContainer>
                        <OilerButton href="https://fossil.oiler.network/docs/">TRY IT OUT</OilerButton>
                    </ButtonContainer>
                    
            </StyledCol>
        </Wrapper>
       
    )
}

export default NaftaContent
