import { createGlobalStyle, ThemeProps } from "styled-components";
import backgroundBlack from "./themes/img/dark-bg.png";

export const OilerGlobal = createGlobalStyle`
  body {
    //background-image: url(${backgroundBlack});
    overflow-x: hidden;
    font-family: 'Montserrat', sans-serif;
    font-weight: ${(props: ThemeProps<any>) => props.theme.fontWeight.medium};
  }
`;
