import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import styled, { ThemeProvider } from "styled-components";
import { darkTheme } from "@oilerKit/themes/dark";
import { OilerGlobal } from "@oilerKit/OilerGlobal";
import OilerContainer from "./oilerKit/OilerContainer";
import OilerNav from "@oilerKit/OilerNav/OilerNav";
import Video from "@components/Video";
import Content from "@components/Content";
import OilerFooter from "@oilerKit/OilerFooter";
import { footerLinks } from "@config/footerLinks";
import NaftaContent from "@components/NaftaContent";
import StakingContent from "@components/StakingContent";
import ForwardsContent from "@components/ForwardsContent";
import OptionsContent from "@components/OptionsContent";

const AppWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  //height: 100%;
  text-align: center;
  //display: flex;
  align-items: center;
  justify-content: center;
  video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }
`;

const StyledOilerContainer = styled(OilerContainer)`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const FooterWrapper = styled.div`
  background: #282828;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
`;


function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <OilerGlobal />
      <AppWrapper>
        <Video />
        <StyledOilerContainer>
          <OilerNav />
          <Content />
        </StyledOilerContainer>
        </AppWrapper>
      <NaftaContent/>
      <StakingContent />
      <ForwardsContent />
      <OptionsContent />
      <FooterWrapper>
      <OilerFooter links={footerLinks} />
      </FooterWrapper>
    </ThemeProvider>
  );
}

export default App;
